export const SET_RM='SET_RM'
export const SET_EMP='SET_EMP'
export const SET_NAME='SET_NAME'
export const SET_EMAIL='SET_EMAIL'

export const SET_INDUSTRY='SET_INDUSTRY'
export const SET_SEGMENT='SET_SEGMENT'

export const GET_RM='GET_RM'
export const GET_RM_FILTER='GET_RM_FILTER'
export const GET_INDUSTRY='GET_INDUSTRY'
export const GET_SEGMENT='SET_SEGMENT'
export const GET_BRANCH='GET_BRANCH'
export const GET_DETAIL_CLIENT='GET_DETAIL_CLIENT'




export const SET_PHONE_NO='SET_PHONE_NO'
export const SET_FAX='SET_FAX'
export const SET_WESITE='SET_WESITE'
export const SET_REMARKS='SET_REMARKS'


export const SET_ADDRESS1='SET_ADDRESS1'
export const SET_ADDRESS2='SET_ADDRESS2'
export const SET_ADDRESS3='SET_ADDRESS3'


export const SET_CLIENT='SET_CLIENT'
export const SET_PAGINATION='SET_PAGINATION'
export const SET_SEARCH='SET_SEARCH'
export const SET_SEARCH2='SET_SEARCH2'
export const CLIENT_FILTER='CLIENT_FILTER'
export const CLIENT_ACTION='CLIENT_ACTION'

export const SET_ID='SET_ID'
export const CLEAR_STATE='CLEAR_STATE'

export const SET_FILTER_CONTACT='SET_FILTER_CONTACT'
export const GET_CONTACT='GET_CONTACT'
export const GET_DATA_EXPORT='GET_DATA_EXPORT'





